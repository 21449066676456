import React, { useCallback, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { PostCommentsHeaderContainer } from '../PostCommentsHeader';
import { PaginationContainer } from '../Pagination';
import { PostCommentsLayout } from '../../components/PostCommentsLayout';
import { PostCommentForm } from '../../components/PostCommentForm';
import { PostCommentList } from '../../components/PostCommentList';
import { addComment, getPostComments } from '../../actions/postComments';
import { requestAuthentication } from '../../actions/user';
import { selectPost } from '../../selectors/post';
import { selectUser } from '../../selectors/user';
import { selectComments } from '../../selectors/postCard';
import { selectListingAppendType } from '../../selectors/settings/selectListingAppendType';
import { prepareCommentsOrdering, preparePageNumber, useGetListing } from '../../utils';
import { TThunkDispatch } from '../../types/redux';
import { POST_CARD_COMMENTS_REQUEST_LIMIT } from '../../constants';
import { ESortOrder } from '../../repositories/journal/v1/get-comments';

interface IHandleSendComment {
  value: string;
  isChecked: boolean;
  parentCommentId?: number;
}

export const PostCommentsContainer = () => {
  const dispatch = useDispatch<TThunkDispatch>();
  const { items } = useSelector(selectComments);
  const post = useSelector(selectPost);
  const { isAuthenticated } = useSelector(selectUser);
  const appendType = useSelector(selectListingAppendType);
  const { search } = useLocation();
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);
  const qsPage = preparePageNumber(searchParams.get('page') as string);
  const qsOrdering = prepareCommentsOrdering(searchParams.get('ordering') as string);
  const wrapperRef = useRef<HTMLDivElement>(null);

  useGetListing(
    () => {
      if (!('id' in post)) {
        return;
      }

      dispatch(
        getPostComments({
          postId: post.id,
          setType: appendType,
          offset: (qsPage - 1) * POST_CARD_COMMENTS_REQUEST_LIMIT,
          limit: POST_CARD_COMMENTS_REQUEST_LIMIT,
          ordering: qsOrdering,
          sortOrder: ESortOrder.Desc,
          withCommentId: undefined,
        }),
      ).finally();
    },
    {
      dependencyNames: ['page', 'ordering'],
      eventTrackerData: {
        page: {
          extra: {},
        },
      },
      scrollOptions: {
        type: 'ref',
        ref: wrapperRef,
      },
    },
  );

  /**
   * Блокирует раскрытие формы комментария и открывает
   * модалку авторизации, если пользователь - гость
   */
  const handleOpenCommentForm = useCallback(() => {
    if (isAuthenticated) {
      return;
    }

    dispatch(requestAuthentication());

    // блокируем разворачивание формы
    return Boolean('Stop event!');
  }, [dispatch, isAuthenticated]);

  /**
   * Отправляет комментарий на бэк или открывает
   * модалку авторизации, если пользователь - гость
   */
  const handleSendComment = useCallback(
    ({ value, isChecked, parentCommentId }: IHandleSendComment) => {
      if (!('id' in post)) {
        return;
      }

      if (!isAuthenticated) {
        dispatch(requestAuthentication());

        return;
      }

      dispatch(
        addComment({
          postType: post.type,
          isSubscriptionChecked: isChecked,
          postId: post.id,
          parentCommentId,
          comment: value,
        }),
      ).finally();
    },
    [dispatch, isAuthenticated, post],
  );

  if (!('id' in post)) {
    return null;
  }

  return (
    <PostCommentsLayout ref={wrapperRef}>
      <PostCommentsHeaderContainer />

      <PostCommentForm
        onSubmit={(value, isChecked) => handleSendComment({ value, isChecked })}
        onFocus={handleOpenCommentForm}
      />

      <PostCommentList
        items={items}
        postAuthorId={post.attributes.authorId}
        onSendComment={handleSendComment}
        onOpenForm={handleOpenCommentForm}
      />

      <PaginationContainer />
    </PostCommentsLayout>
  );
};
