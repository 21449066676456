import { EType } from '../../repositories/journal/entities/journal/JournalListAttributesSchema';
import { PostTypePostCardUrlPrefixes } from '../../constants';

interface IBuildPostUrl {
  type: EType;
  slug: string;
  id: number;
}

/** Строит url материала */
export const buildPostUrl = ({ type, slug, id }: IBuildPostUrl): string => {
  return `/${PostTypePostCardUrlPrefixes[type]}-${slug}-${id}/`;
};
