import React from 'react';
import { Eye16 as IconFunctionalShow16 } from '@cian/ui-kit/icons/16/eye';

import { IconWithCount } from '../../../IconWithText';
import { IJournalAttributesSchema } from '../../../../repositories/journal/entities/journal/JournalAttributesSchema';

interface INumViews {
  /** Количество просмотров */
  children?: IJournalAttributesSchema['numViews'];
}

/**
 * Количество просмотров
 */
export const NumViews = ({ children }: INumViews) =>
  children ? <IconWithCount count={children} icon={<IconFunctionalShow16 />} /> : null;
