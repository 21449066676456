import { TThunkAction } from '../../../types/redux';
import { getPostCardNewsLoading } from './getPostCardNewsLoading';
import { getPostCardNewsSucceed } from './getPostCardNewsSucceed';
import { getPostCardNewsFailed } from './getPostCardNewsFailed';
import {
  fetchGetNewsList,
  TGetNewsListResponse,
  IGetNewsListRequest,
} from '../../../repositories/journal/v2/get-news-list';
import { throwError } from '../../../utils';

type TGetPostCardNews = Pick<
  IGetNewsListRequest,
  'tag' | 'offset' | 'limit' | 'ordering' | 'category' | 'regionId' | 'endDate'
>;

export const getPostCardNews = ({ ...otherParams }: TGetPostCardNews = {}): TThunkAction<Promise<void>> => {
  return async (dispatch, getState, context) => {
    const { httpApi, logger } = context;
    const {
      userNew: { isB2BModeEnabled },
      geo: { currentRegion },
    } = getState();

    dispatch(getPostCardNewsLoading());

    try {
      const { response, statusCode }: TGetNewsListResponse = await fetchGetNewsList({
        httpApi,
        parameters: {
          regionId: currentRegion.id,
          b2b: isB2BModeEnabled,
          ...otherParams,
        },
      });

      if (statusCode !== 200) {
        throwError({ message: 'Не удалось загрузить список новостей', domain: 'actions.getPostCardNews', statusCode });

        return;
      }

      dispatch(getPostCardNewsSucceed({ meta: response.meta, items: response.data }));
    } catch (e) {
      logger.error(e, {
        domain: 'actions.getPostCardNews',
      });

      dispatch(getPostCardNewsFailed());
    }
  };
};
