import React from 'react';
import { getTime } from '@cian/utils';

import { formatDate } from '../../utils';
import * as s from './DatePublished.css';

interface IDateTimePublished {
  /** Дата строкой вв ISO */
  children: string;
}

/**
 * Дата и время публикации
 */
export const DateTimePublished = ({ children }: IDateTimePublished) => {
  const date = new Date(children);
  const time = getTime(new Date(children));

  return (
    <div className={s['wrapper']} title={`${formatDate(date)}, ${time}`}>
      {formatDate(date, { skipCurrentYear: true })}, {time}
    </div>
  );
};
