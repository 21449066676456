import React from 'react';
import { Link } from 'react-router-dom';

import { IconWithCount, IconWithText } from '../../../IconWithText';
import { IconMessage16 } from '../../../Icons';
import { IJournalAttributesSchema } from '../../../../repositories/journal/entities/journal/JournalAttributesSchema';
import { IPost } from '../../Post';
import * as s from './NumComments.css';

interface INumComments extends Pick<IPost, 'disableComments' | 'postUrl'> {
  children: IJournalAttributesSchema['commentsCount'];
}

/**
 * Количество комментариев
 */
export const NumComments = ({ children, postUrl, disableComments }: INumComments) => {
  if (disableComments) {
    return null;
  }

  return (
    <Link className={s['wrapper']} to={`${postUrl}#comments`}>
      {children ? (
        <IconWithCount count={children} icon={<IconMessage16 />} />
      ) : (
        <IconWithText text={'Обсудить'} icon={<IconMessage16 />} />
      )}
    </Link>
  );
};
