import * as React from 'react';
import { IIconProps } from '@cian/ui-kit/icons/internal/types';

export const IconNotepad16 = ({ color }: IIconProps) => {
  const currentColor = color ? `var(--${color})` : 'currentColor';

  return (
    // eslint-disable-next-line react/forbid-elements
    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="12" viewBox="0 0 10 12">
      <path
        fill={currentColor}
        fillRule="evenodd"
        d="M0 11a1 1 0 0 0 1 1h9v-1H2c-.434 0-.65-.2-.65-.488 0-.29.169-.512.65-.512h8V0c-.018.01-.465 0-1 0H5v4L3.504 2.71 2 4V0H1a1 1 0 0 0-1 1v10z"
      />
    </svg>
  );
};
