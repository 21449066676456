import React from 'react';

import { formatDate } from '../../utils';
import * as s from './DatePublished.css';

interface IDatePublished {
  /** Дата строкой вв ISO */
  children: string;
}

/**
 * Дата публикации
 */
export const DatePublished = ({ children }: IDatePublished) => {
  const date = new Date(children);

  return (
    <div className={s['wrapper']} title={formatDate(date)}>
      {formatDate(date, { skipCurrentYear: true })}
    </div>
  );
};
