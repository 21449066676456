import { TPostState } from '../../../../types/post';
import { buildPostUrl } from '../../../../utils';

/** Возвращает набор seo-параметров из поста */
export const getPostMeta = (post: TPostState) => {
  if (!('id' in post)) {
    return;
  }

  const {
    id,
    attributes: { type, slug, seoTitle, seoDescription, seoKeywords, image },
  } = post;

  return [seoTitle || '', seoDescription || '', seoKeywords || '', buildPostUrl({ type, slug, id }), image || ''];
};
