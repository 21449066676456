import { TThunkAction } from '../../../types/redux';

import { fetchAddComment, TAddCommentResponse, IAddCommentRequest } from '../../../services/postComments/addComment';
import { throwError } from '../../../utils';

interface IAddComment {
  postType: IAddCommentRequest['content_type_model'];
  isSubscriptionChecked: IAddCommentRequest['is_subscribed_to_comments'];
  postId: IAddCommentRequest['object_pk'];
  parentCommentId: IAddCommentRequest['parent_id'];
  comment: IAddCommentRequest['comment'];
}

export const addComment = ({
  postType,
  isSubscriptionChecked,
  postId,
  parentCommentId,
  comment,
}: IAddComment): TThunkAction<Promise<void>> => {
  return async (dispatch, getState, context) => {
    const { httpApi, logger } = context;

    try {
      const { statusCode }: TAddCommentResponse = await fetchAddComment({
        httpApi,
        parameters: {
          content_type_model: postType,
          is_subscribed_to_comments: isSubscriptionChecked,
          object_pk: postId,
          parent_id: parentCommentId,
          comment,
        },
      });

      if (statusCode !== 201) {
        throwError({ message: 'Не удалось добавить комментарий', statusCode, domain: 'actions.addComment' });

        return;
      }
    } catch (e) {
      logger.error(e, {
        domain: 'actions.addComment',
      });
    }
  };
};
