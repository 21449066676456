import React from 'react';
import { Avatar, UIHeading4, Like16 as IconLike16 } from '@cian/ui-kit';
import { Link } from 'react-router-dom';

import { TDiscussion } from '../../../../types/discussions';
import { buildPostUrl } from '../../../../utils';
import { IconWithCount } from '../../../IconWithText';
import { IconMessage16 } from '../../../Icons';
import * as s from './SidebarDiscussionsItem.css';

/**
 * Элемент ленты обсуждений в сайдбаре
 */
export const SidebarDiscussionsItem = ({
  content: { title, slug, type, commentsCount, likesCount, id: postId },
  comment,
  id: commentId,
  commentUsers,
}: TDiscussion) => {
  const link = `${buildPostUrl({ type, slug, id: postId })}#comment-${commentId}`;

  return (
    <div className={s['wrapper']}>
      <UIHeading4>
        <Link to={buildPostUrl({ type, slug, id: postId })} className={s['post-link']}>
          {title}
        </Link>
      </UIHeading4>

      <Link to={link} className={s['comment-link']}>
        <div className={s['comment-text']}>{comment}</div>
      </Link>

      <Link to={link} className={s['footnote']}>
        <div className={s['avatars']}>
          {commentUsers.map(({ avatar, userId }) => (
            <Avatar key={userId} src={avatar} type="user" size={30} />
          ))}
        </div>

        {commentsCount ? <IconWithCount count={commentsCount} icon={<IconMessage16 />} /> : null}
        {likesCount ? <IconWithCount count={likesCount} icon={<IconLike16 />} /> : null}
      </Link>
    </div>
  );
};
