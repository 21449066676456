import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { selectPost } from '../../selectors/post';
import { useGetPost } from '../../utils/useGetPost';
import { TThunkDispatch } from '../../types/redux';
import { getPost } from '../../actions/post';
import { buildPostUrl, parsePostCardPath } from '../../utils';
import { ERequestStatus } from '../../types/requestStatus';
import { SkeletonPost, Post } from '../../components/Post';
import { selectCurrentRegion } from '../../selectors/geo/currentRegion';
import { findPostRegionName } from './helpers/findPostRegionName';
import { selectRegions } from '../../selectors/regions/selectRegions';

export const PostContainer = () => {
  const dispatch = useDispatch<TThunkDispatch>();
  const { pathname } = useLocation();
  const { id: qsPostId } = parsePostCardPath(pathname);
  const post = useSelector(selectPost);
  const currentRegion = useSelector(selectCurrentRegion);
  const { items: dictionaryRegions } = useSelector(selectRegions);

  useGetPost(
    () => {
      dispatch(getPost({ id: qsPostId })).finally();
    },
    { dependencyNames: ['pathname'] },
  );

  if (post.status === ERequestStatus.Loading) {
    return <SkeletonPost />;
  }

  if (!('id' in post)) {
    return null;
  }

  const {
    id,
    attributes: {
      commentsCount,
      content,
      datePublish,
      disableComments,
      image,
      numViews,
      subtitle,
      title,
      type,
      regions,
      slug,
      updatedAt,
      authorObject,
      authorId,
    },
  } = post;

  const preparedContent = content || '';

  return (
    <Post
      authorPostsCount={undefined}
      authorId={authorId || undefined}
      authorName={authorObject?.fullName || undefined}
      commentsCount={commentsCount}
      content={preparedContent}
      dateModified={updatedAt}
      datePublished={datePublish}
      disableComments={disableComments}
      image={image}
      numViews={numViews}
      postUrl={buildPostUrl({ type, slug, id })}
      postRegionName={findPostRegionName({
        globalRegionId: currentRegion.id,
        postRegionIds: regions || [],
        dictionaryRegions,
      })}
      subtitle={subtitle}
      title={title}
      type={type}
    />
  );
};
