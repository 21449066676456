import React from 'react';

import { IconDocuments40 } from '../../Icons';
import { ListingItemType } from '../../../constants/microdata';
import {
  EType,
  IArticlesMainAttributesSchema,
} from '../../../repositories/journal/entities/journal/ArticlesMainAttributesSchema';
import { Headnote, IHeadnote } from '../components/Headnote';
import { Title } from '../components/Title';
import { Description } from '../components/Description';
import * as s from './DocumentListingItem.css';

interface IDocumentListingItem extends IHeadnote {
  url: string;
  children?: React.ReactNode;
  title?: IArticlesMainAttributesSchema['title'];
  subtitle?: string;
  onArticleClick?(e: React.MouseEvent): void;
}

/**
 * Элемент списка документов
 */
export const DocumentListingItem = ({
  title,
  url,
  subtitle,
  rubric,
  datePublish,
  numComments = 0,
  numLikes = 0,
  children,
  noIndex,
  onRubricButtonClick,
  onArticleClick,
}: IDocumentListingItem) => {
  return (
    <article className={s['wrapper']} itemScope itemType={ListingItemType[EType.Articles]}>
      <div className={s['icon-wrapper']}>
        <IconDocuments40 />
      </div>

      <div className={s['content-wrapper']} itemProp="articleBody">
        <Headnote
          rubric={rubric}
          noIndex={noIndex}
          datePublish={datePublish}
          numComments={numComments}
          numLikes={numLikes}
          onRubricButtonClick={onRubricButtonClick}
        />

        <Title url={url} noIndex={noIndex} onClick={onArticleClick}>
          {title}
        </Title>

        <Description>{subtitle}</Description>

        {children}
      </div>
    </article>
  );
};
