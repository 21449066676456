/* eslint-disable */
import { IHttpApi, IHttpApiFetchConfig } from '@cian/http-api/shared/http';
import {
  TAddCommentModel,
  IAddCommentRequest,
  TAddCommentResponse,
} from './types';

const defaultConfig: TAddCommentModel = {
  apiType: 'private',
  requestType: 'formData',
  assertStatusCodes: [201, 400],
  method: 'POST',
  microserviceName: 'backend-content',
  pathApi: '/v1/add-comment',
  hostType: 'api',
};

function createAddCommentModel(parameters: IAddCommentRequest): TAddCommentModel {
  return {
    ...defaultConfig,
    parameters,
  };
}

export interface IAddCommentOptions<TResponse200, TResponse400> {
  httpApi: IHttpApi;
  parameters: IAddCommentRequest;
  config?: IHttpApiFetchConfig;
}

async function fetchAddComment<TResponse200, TResponse400>({
  httpApi,
  parameters,
  config,
}: IAddCommentOptions<TResponse200, TResponse400>): Promise<
  TResponse200 | TResponse400 | TAddCommentResponse
  > {
  return await httpApi.fetch(createAddCommentModel(parameters), { ...config, requestConfig: { withCredentials: true } });
}

export { defaultConfig, createAddCommentModel, fetchAddComment };
