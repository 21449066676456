import React from 'react';

import { IPost } from '../../Post';
import * as s from './RegionName.css';

interface IRegionName {
  /** Название региона */
  children?: IPost['postRegionName'];
}

/**
 * Название региона
 */
export const RegionName = ({ children }: IRegionName) =>
  children ? <div className={s['wrapper']}>{children}</div> : null;
