import React from 'react';
import { Image } from '@cian/ui-kit';

import * as s from './MainImage.css';

export interface IMainImage {
  src: string;
  alt?: string;
}

/**
 * Компонент главной картинки
 */
export const MainImage = ({ src, alt }: IMainImage) => {
  return (
    <div className={s['wrapper']}>
      <Image src={src} alt={alt} objectFit="cover" preloader theme="light" title={alt} itemProp="image" />
    </div>
  );
};
