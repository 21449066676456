import React, { useEffect } from 'react';

import { SidebarNews } from '../../components/SidebarNews';
import { useDispatch, useSelector } from 'react-redux';
import { TThunkDispatch } from '../../types/redux';
import { ERequestStatus } from '../../types/requestStatus';
import { getNewsList } from '../../actions/newsList';
import { EOrdering } from '../../repositories/journal/v2/get-news-list';
import { POST_CARD_NEWS_REQUEST_END_DATE, POST_CARD_NEWS_REQUEST_LIMIT } from '../../constants';
import { getDateForNDaysAgo } from '../../utils';
import { selectNews } from '../../selectors/postCard';
import { SkeletonSidebarDiscussions } from '../../components/SidebarDiscussions';

export const SidebarNewsContainer = () => {
  const dispatch = useDispatch<TThunkDispatch>();
  const { items: news, status: newsStatus } = useSelector(selectNews);

  /** Запрашивает список новостей, если еще ни разу не запрашивал */
  useEffect(() => {
    if (newsStatus === ERequestStatus.Initial) {
      dispatch(
        getNewsList({
          ordering: EOrdering.Num_views,
          limit: POST_CARD_NEWS_REQUEST_LIMIT,
          endDate: getDateForNDaysAgo(POST_CARD_NEWS_REQUEST_END_DATE).toISOString(),
        }),
      ).finally();
    }
  }, [dispatch, newsStatus]);

  if (newsStatus === ERequestStatus.Loading) {
    return <SkeletonSidebarDiscussions />;
  }

  if (!news?.length) {
    return null;
  }

  return <SidebarNews items={news} />;
};
