import { ITypedReduxAction } from '../../../types/redux/actionType';
import { INewsList } from '../../../types/newsList';

export enum EGetPostCardNewsActionType {
  Loading = 'postCardNews/getPostCardNews/loading',
  Succeed = 'postCardNews/getPostCardNews/succeed',
  Failed = 'postCardNews/getPostCardNews/failed',
}

export type TGetPostCardNewsLoading = ITypedReduxAction<EGetPostCardNewsActionType.Loading>;
export type TGetPostCardNewsSucceed = ITypedReduxAction<EGetPostCardNewsActionType.Succeed, INewsList>;
export type TGetPostCardNewsFailed = ITypedReduxAction<EGetPostCardNewsActionType.Failed>;

export type TGetPostCardNews = TGetPostCardNewsLoading | TGetPostCardNewsSucceed | TGetPostCardNewsFailed;
