/* eslint-disable */

//tslint:disable

import { IHttpApi, IHttpApiFetchConfig } from '@cian/http-api/shared/http';
import {
  TGetCommentsModel,
  IGetCommentsRequest,
  IMappers,
  IGetCommentsResponse,
  IGetCommentsResponseError,
  TGetCommentsResponse,
} from './types';

const defaultConfig: TGetCommentsModel = {
  apiType: 'public',
  assertStatusCodes: [200, 400],
  method: 'GET',
  microserviceName: 'journal',
  pathApi: '/v1/get-comments/',
  hostType: 'api',
} as TGetCommentsModel;

function createGetCommentsModel(parameters: IGetCommentsRequest): TGetCommentsModel {
  return {
    ...defaultConfig,
    parameters,
  };
}

export interface IGetCommentsOptions<TResponse200, TResponse400> {
  httpApi: IHttpApi;
  parameters: IGetCommentsRequest;
  config?: IHttpApiFetchConfig;
  mappers?: IMappers<TResponse200, TResponse400>;
}

async function fetchGetComments<TResponse200, TResponse400>({
  httpApi,
  parameters,
  config,
  mappers,
}: IGetCommentsOptions<TResponse200, TResponse400>): Promise<TResponse200 | TResponse400 | TGetCommentsResponse> {
  const { response, statusCode } = await httpApi.fetch(createGetCommentsModel(parameters), config);

  if (mappers && statusCode in mappers) {
    if (statusCode === 200) {
      return mappers[200](response as IGetCommentsResponse);
    }

    if (statusCode === 400) {
      return mappers[400](response as IGetCommentsResponseError);
    }
  }

  return { response, statusCode } as TGetCommentsResponse;
}

export { defaultConfig, createGetCommentsModel, fetchGetComments };
