import React, { useCallback } from 'react';
import { Image, Like16 as IconLike16 } from '@cian/ui-kit';

import { IArticlesMainAttributesRubricSchema } from '../../repositories/journal/entities/journal/ArticlesMainAttributesRubricSchema';
import { IArticlesMainAttributesSchema } from '../../repositories/journal/entities/journal/ArticlesMainAttributesSchema';
import { IRubricLink, RubricButton } from '../RubricButton';
import { formatDate } from '../../utils';
import { IconWithCount } from '../IconWithText';
import { IconMessage16 } from '../Icons';
import { ListingItemType } from '../../constants/microdata';
import { IStatusLink, Status } from './components/Status';
import * as s from './ListingItem.css';

export interface IListingItemRubric {
  /** Название */
  name: string;
  /** Ссылка */
  url: IRubricLink['url'];
}

export interface IListingItem extends Partial<Pick<IArticlesMainAttributesSchema, 'title' | 'image' | 'datePublish'>> {
  subtitle?: IArticlesMainAttributesSchema['subtitle'] | React.ReactNode;
  rubric?: IListingItemRubric;
  numComments?: IArticlesMainAttributesSchema['commentsCount'];
  numLikes?: IArticlesMainAttributesSchema['likesCount'];
  url: string;
  children?: React.ReactNode;
  noIndex?: boolean;
  type: IArticlesMainAttributesSchema['type'];
  status?: IStatusLink;
  onRubricButtonClick?(e: React.MouseEvent, url?: IArticlesMainAttributesRubricSchema['url']): void;
  onClick?(e: React.MouseEvent): void;
}

/**
 * Компонент элемента списка
 */
export const ListingItem = ({
  title,
  url,
  subtitle,
  image,
  rubric,
  datePublish,
  numComments = 0,
  numLikes = 0,
  children,
  noIndex,
  type,
  status,
  onRubricButtonClick,
  onClick,
}: IListingItem) => {
  const handleArticleClick = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      e.preventDefault();

      if (onClick) {
        onClick(e);
      }
    },
    [onClick],
  );

  const handleRubricClick = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      e.preventDefault();

      if (!rubric) {
        return;
      }

      if (onRubricButtonClick) {
        onRubricButtonClick(e, rubric?.url);
      }
    },
    [onRubricButtonClick, rubric],
  );

  return (
    <article className={s['wrapper']} itemScope itemType={ListingItemType[type]}>
      <div className={s['content-wrapper']} itemProp="articleBody">
        {(rubric || datePublish || numComments) && (
          <div className={s['headnote']} itemProp="hasPart" itemScope itemType="http://schema.org/PublicationIssue">
            {rubric && (
              <RubricButton url={rubric.url} onClick={handleRubricClick} noIndex={noIndex}>
                {rubric.name.toLowerCase()}
              </RubricButton>
            )}

            {datePublish && (
              <div className={'date-publish'} itemProp="datePublished">
                {formatDate(new Date(datePublish))}
              </div>
            )}

            {numComments ? <IconWithCount count={numComments} icon={<IconMessage16 />} /> : null}
            {numLikes ? <IconWithCount count={numLikes} icon={<IconLike16 />} /> : null}
            {!image && status && (
              <div className={s['status-wrapper']}>
                <Status {...status} />
              </div>
            )}
          </div>
        )}

        {title && (
          <a
            href={url}
            className={s['title']}
            title={title}
            itemProp="headline"
            rel={noIndex ? 'nofollow' : undefined}
            onClick={handleArticleClick}
          >
            {title}
          </a>
        )}

        {subtitle && (
          <div className={s['subtitle']} itemProp="description">
            {subtitle}
          </div>
        )}

        {children}
      </div>

      {image && (
        <div className={s['image-wrapper']}>
          {status && (
            <div className={s['status-wrapper']}>
              <Status {...status} />
            </div>
          )}

          <a
            href={url}
            className={s['image']}
            title={title}
            onClick={handleArticleClick}
            rel={noIndex ? 'nofollow' : undefined}
          >
            <Image
              alt={title}
              borderRadius={0}
              objectFit="cover"
              preloader
              src={image}
              theme="light"
              title={title}
              itemProp="image"
            />
          </a>
        </div>
      )}
    </article>
  );
};
