import React, { useCallback } from 'react';
import { Like16 as IconLike16 } from '@cian/ui-kit';

import { IconMessage16 } from '../../../Icons';
import { IRubricLink, RubricButton } from '../../../RubricButton';
import { formatDate } from '../../../../utils';
import { IconWithCount } from '../../../IconWithText';
import { IArticlesMainAttributesSchema } from '../../../../repositories/journal/entities/journal/ArticlesMainAttributesSchema';
import { IArticlesMainAttributesRubricSchema } from '../../../../repositories/journal/entities/journal/ArticlesMainAttributesRubricSchema';
import * as s from './Headnote.css';

interface IRubric extends Pick<IRubricLink, 'url'> {
  name: IRubricLink['children'];
}

export interface IHeadnote {
  rubric?: IRubric;
  noIndex?: IRubricLink['noIndex'];
  datePublish?: IArticlesMainAttributesSchema['datePublish'];
  numComments?: IArticlesMainAttributesSchema['commentsCount'];
  numLikes?: IArticlesMainAttributesSchema['likesCount'];
  beforeAll?: React.ReactNode;
  afterAll?: React.ReactNode;
  onRubricButtonClick?(e: React.MouseEvent, url?: IArticlesMainAttributesRubricSchema['url']): void;
}

/**
 * Блок с рубрикой, датой публикации, просмотрами и т.п.
 */
export const Headnote = ({
  rubric,
  noIndex,
  datePublish,
  numComments,
  numLikes,
  beforeAll,
  afterAll,
  onRubricButtonClick,
}: IHeadnote) => {
  const handleRubricClick = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      e.preventDefault();

      if (!rubric) {
        return;
      }

      if (onRubricButtonClick) {
        onRubricButtonClick(e, rubric?.url);
      }
    },
    [onRubricButtonClick, rubric],
  );

  if (!rubric && !datePublish && !numComments && !numLikes && !beforeAll && !afterAll) {
    return null;
  }

  return (
    <div className={s['wrapper']} itemProp="hasPart" itemScope itemType="http://schema.org/PublicationIssue">
      {beforeAll}
      {rubric && (
        <RubricButton url={rubric.url} onClick={handleRubricClick} noIndex={noIndex}>
          {rubric.name.toLowerCase()}
        </RubricButton>
      )}

      {datePublish && <div itemProp="datePublished">{formatDate(new Date(datePublish))}</div>}
      {numComments ? <IconWithCount count={numComments} icon={<IconMessage16 />} /> : null}
      {numLikes ? <IconWithCount count={numLikes} icon={<IconLike16 />} /> : null}
      {afterAll}
    </div>
  );
};
