import React from 'react';

import { Skeleton, RoundedSkeleton } from '../../Skeleton';
import * as s from './DocumentListingItem.css';

/**
 * Скелетон для элемента списка Документ
 */
export const SkeletonDocumentListingItem = () => {
  return (
    <div className={s['wrapper']}>
      <RoundedSkeleton size={120} />

      <div className={s['content-wrapper']}>
        <div className={s['headnote']}>
          <Skeleton width={80} />
          <Skeleton width={100} />
          <Skeleton width={30} />
        </div>

        <div>
          <Skeleton width={'90%'} height={24} margin={'0 0 14px 0'} />
          <Skeleton width={'80%'} height={24} />
        </div>

        <Skeleton width={'60%'} />
      </div>
    </div>
  );
};
