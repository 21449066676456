import { useEffect, useState } from 'react';

import { useCurrentListing } from '../useCurrentListing';

/**
 * Слушает мету и высчитывает общее количество страниц
 */
export const useCurrentListingPagesCount = () => {
  const {
    meta: { total: itemsCount, limit },
  } = useCurrentListing();
  const [pagesCount, setPagesCount] = useState(0);

  useEffect(() => {
    if (!itemsCount || !limit) {
      setPagesCount(0);

      return;
    }

    setPagesCount(Math.ceil(itemsCount / limit));
  }, [limit, itemsCount]);

  return pagesCount;
};
