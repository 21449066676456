/* eslint-disable */

//tslint:disable

import { IModel, IModelResponse } from '@cian/http-api/shared/model';
import { ICommentParentSchema } from '../../entities/comments/CommentParentSchema';
import { ICommentsMetaSchema } from '../../entities/comments/CommentsMetaSchema';

export type TGetCommentsModel = IModel<IGetCommentsRequest, TGetCommentsResponse>;

export interface IGetCommentsRequest {
  /** ID журнала **/
  journalId?: number | null;
  /** Количество записей **/
  limit?: number | null;
  /** Сдвиг **/
  offset?: number | null;
  /** Сортировка **/
  ordering?: EOrdering | null;
  /** Порядок сортировки **/
  sortOrder?: ESortOrder | null;
  /** С комментарием **/
  withCommentId?: number | null;
}

export type TGetCommentsResponse = IGetCommentsResponse200 | IGetCommentsResponse400;

export interface IGetCommentsResponse200 extends IModelResponse<IGetCommentsResponse> {
  statusCode: 200;
}

export interface IGetCommentsResponse400 extends IModelResponse<IGetCommentsResponseError> {
  statusCode: 400;
}

export interface IGetCommentsResponse {
  /** Список комментариев **/
  data: ICommentParentSchema[];
  /** Мета данные **/
  meta: ICommentsMetaSchema;
}

export interface IGetCommentsResponseError {
  errors: any[];
  message: string;
}

export type TResponses = IGetCommentsResponse | IGetCommentsResponseError;

export enum EOrdering {
  /** Понравившееся **/
  Likes_count = 'likes_count',
  /** Дата публикации **/
  Published_at = 'published_at',
}

export enum ESortOrder {
  /** По возрастанию **/
  Asc = 'asc',
  /** По убыванию **/
  Desc = 'desc',
}

export interface IMappers<TResponse200, TResponse400> {
  200(response: IGetCommentsResponse): TResponse200;
  400(response: IGetCommentsResponseError): TResponse400;
}
