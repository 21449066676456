import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { PostCommentsHeader } from '../../components/PostCommentsHeader';
import { selectComments } from '../../selectors/postCard';
import { EOrdering } from '../../repositories/journal/v1/get-comments';
import { prepareCommentsOrdering } from '../../utils';
import { ERequestStatus } from '../../types/requestStatus';

/**
 * Контейнер шапки листинга комментариев
 */
export const PostCommentsHeaderContainer = () => {
  const { search } = useLocation();
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);
  const history = useHistory();
  const [ordering, setOrdering] = useState<EOrdering>(EOrdering.Likes_count);
  const {
    status: commentsStatus,
    meta: { total },
  } = useSelector(selectComments);

  /**
   * Сохраняет состояние сортировки в стэйт
   */
  useEffect(() => {
    const qsOrdering = searchParams.get('ordering');
    const qsOrderingPrepared = qsOrdering ? prepareCommentsOrdering(qsOrdering) : '';

    if (!qsOrderingPrepared || ordering === qsOrderingPrepared) {
      return;
    }

    setOrdering(qsOrderingPrepared);
  }, [ordering, searchParams]);

  /**
   * Устанавливает сортировку
   */
  const handleSorting = useCallback(() => {
    const newOrdering = ordering === EOrdering.Likes_count ? EOrdering.Published_at : EOrdering.Likes_count;

    searchParams.set('ordering', newOrdering);
    searchParams.delete('page');
    history.push(`?${searchParams.toString()}`);
  }, [history, ordering, searchParams]);

  /**
   * Устанавливает подписку на комменты
   */
  const handleSubscription = useCallback(() => {
    // TODO: Сделать подписку в модалке!
  }, []);

  /**
   * Задисэйблены ли контролы
   */
  const isDisabled = useCallback(() => {
    return [ERequestStatus.Loading, ERequestStatus.Failed].includes(commentsStatus);
  }, [commentsStatus]);

  return (
    <PostCommentsHeader
      commentsCount={total}
      ordering={ordering}
      onSort={handleSorting}
      onSwitch={handleSubscription}
      isDisabled={isDisabled()}
    />
  );
};
