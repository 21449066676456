import React from 'react';
import { Avatar } from '@cian/ui-kit';
import { HonestWork, PopupDirection } from '@cian/honestwork-component';

import s from './UserCard.css';

export interface IUserCardParams {
  /** Ссылка на изображение */
  imageSrc?: string;
  /** Имя пользователя */
  name: string;
  /** Описание */
  description?: string;
  /** Размер */
  size?: 'XL' | 'L' | 'M' | 'S';
  /** Иконка доверенного пользователя */
  isTrusted?: boolean;
  /** Ссылка пользователя */
  url?: string;
  /** Событие клика */
  onClick?(e: React.MouseEvent): void;
  /** Выделить ли имя жирным стилем */
  isStrongName?: boolean;
  /** Дополнительный контент после имени */
  afterName?: React.ReactNode;
  /** Дополнительный контент под всем текстом */
  afterAll?: React.ReactNode;
}

/**
 * Карточка пользователя
 */
export const UserCard = ({
  imageSrc,
  name,
  description,
  size = 'M',
  isTrusted = false,
  url,
  onClick,
  isStrongName,
  afterName,
  afterAll,
}: IUserCardParams) => {
  const sizes = {
    XL: 64,
    L: 48,
    M: 40,
    S: 30,
  };
  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();

    if (onClick) {
      onClick(e);
    }
  };

  return (
    <div className={s['wrapper']}>
      <div className={s['image']} onClick={handleClick}>
        {url ? (
          <a href={url} onClick={handleClick}>
            <Avatar src={imageSrc} type="user" size={sizes[size]} />
          </a>
        ) : (
          <Avatar src={imageSrc} type="user" size={sizes[size]} />
        )}
      </div>

      <div className={s['content']}>
        <div className={s['name-wrapper']}>
          {url ? (
            <a
              href={url}
              className={`${s['name']} ${isStrongName ? s['_strong'] : ''}`}
              onClick={handleClick}
              title={name}
            >
              {name}
            </a>
          ) : (
            <div className={`${s['name']} ${isStrongName ? s['_strong'] : ''}`} onClick={handleClick} title={name}>
              {name}
            </div>
          )}

          {isTrusted && <HonestWork className={s['icon-trusted']} direction={PopupDirection.RIGHT} />}
          {afterName}
        </div>

        {description && (
          <div className={s['description']} title={description}>
            {description}
          </div>
        )}

        {afterAll}
      </div>
    </div>
  );
};
