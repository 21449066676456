import React from 'react';

import * as s from './Typograhy.css';

/**
 * Декоратор типографических стилей для поста
 */
export const Typography = ({ children }: { children: React.ReactNode }) => (
  <div className={s['wrapper']}>{children}</div>
);
