import React, { forwardRef } from 'react';

import * as s from './CommentFormSwitcher.css';

export interface ICommentFormSwitcher {
  onClick?(e: React.MouseEvent): void;
}

/**
 * Кнопка переключения видимости формы комментария
 * !Важно сохранить текущую структуру вложенности:
 * <div ref={ref}>
 *   <div>
 *     Кнопка
 *   </div>
 * </div>
 */
export const CommentFormSwitcher = forwardRef<HTMLDivElement, ICommentFormSwitcher>(({ onClick }, ref) => {
  return (
    <div ref={ref}>
      <div>
        <div className={s['button']} onClick={onClick}>
          Ответить
        </div>
      </div>
    </div>
  );
});

CommentFormSwitcher.displayName = 'CommentFormSwitcher';
