import React from 'react';

import { ICommentParentSchema } from '../../repositories/journal/entities/comments/CommentParentSchema';
import { ICommentSchema } from '../../repositories/journal/entities/comments/CommentSchema';
import { IListItem, ListItem } from './components/ListItem';
import * as s from './PostCommentList.css';

interface ISendComment {
  parentCommentId: number;
  value: string;
  isChecked: boolean;
}

export interface IPostCommentList {
  items: ICommentParentSchema[] | ICommentSchema[];
  postAuthorId?: IListItem['postAuthorId'];
  onSendComment({ parentCommentId, value, isChecked }: ISendComment): void;
  onOpenForm?(e: React.MouseEvent): boolean | undefined;
}

/**
 * Список комментариев
 */
export const PostCommentList = ({ items, postAuthorId, onSendComment, onOpenForm }: IPostCommentList) => {
  return (
    <div className={s['wrapper']}>
      {items.map(({ id, datePublish, authorObject, bestAdvice, comment, ...props }) => {
        return (
          <ListItem
            key={id}
            isBestAdvice={bestAdvice}
            datePublished={datePublish}
            authorObject={authorObject}
            postAuthorId={postAuthorId}
            comment={comment}
            onSendComment={(value, isChecked) => onSendComment({ parentCommentId: id, value, isChecked })}
            onOpenForm={onOpenForm}
          >
            {'comments' in props && props?.comments?.length && (
              <PostCommentList items={props.comments} onSendComment={onSendComment} onOpenForm={onOpenForm} />
            )}
          </ListItem>
        );
      })}
    </div>
  );
};
