import React from 'react';

import { prepareUserAvatar } from '../../utils';
import { CIAN_URL } from '../../constants';
import { IUserCardParams, UserCard } from './UserCard';
import { IUserDataItemSchema } from '../../repositories/journal/entities/user_data_item/UserDataItemSchema';

interface IUserCardForAuthorObject extends IUserDataItemSchema {
  size?: IUserCardParams['size'];
  onClick?(e: React.MouseEvent): void;
  isStrongName?: IUserCardParams['isStrongName'];
  afterName?: IUserCardParams['afterName'];
  afterAll?: IUserCardParams['afterAll'];
}

/**
 * Карточка пользователя для объекта пользователя authorObject
 */
export const UserCardForAuthorObject = ({
  avatar,
  fullName,
  companyName,
  userId,
  userTrust,
  specialistLink,
  size,
  onClick,
  isStrongName,
  afterName,
  afterAll,
}: IUserCardForAuthorObject) => {
  const url = specialistLink ? `${CIAN_URL}${specialistLink}` : undefined;

  const handleClick = (e: React.MouseEvent) => {
    if (!url) {
      return;
    }

    if (onClick) {
      return onClick(e);
    }

    e.preventDefault();
    window.open(url, '_blank');
  };

  return (
    <UserCard
      imageSrc={prepareUserAvatar(avatar || undefined)}
      name={fullName || companyName || (userId ? `ID: ${userId}` : 'Аноним')}
      description={(fullName && companyName) || undefined}
      isTrusted={userTrust || undefined}
      url={url}
      size={size}
      isStrongName={isStrongName}
      afterName={afterName}
      afterAll={afterAll}
      onClick={handleClick}
    />
  );
};
