import React, { forwardRef } from 'react';

import * as s from './PostCommentsLayout.css';

/**
 * Декоратор для компонента PostCommentsContainer
 */
export const PostCommentsLayout = forwardRef<HTMLDivElement, { children: React.ReactNode }>(({ children }, ref) => (
  <div ref={ref} className={s['wrapper']}>
    {children}
  </div>
));

PostCommentsLayout.displayName = 'PostCommentsLayout';
