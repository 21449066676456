import { ERequestStatus } from '../../types/requestStatus';
import { TBlogListState } from '../../types/blogList';
import { EOrdering } from '../../repositories/journal/entities/journal/JournalMetaV2Schema';
import { EGetBlogListActionType, TBlogListActions, EResetBlogListActionType } from '../../actions/blogList';
import { BLOG_LIST_REQUEST_LIMIT } from '../../constants';

export const defaultState: TBlogListState = {
  status: ERequestStatus.Initial,
  meta: {
    b2b: false,
    limit: BLOG_LIST_REQUEST_LIMIT,
    offset: 0,
    regionId: 0,
    category: null,
    tag: null,
    total: 0,
    ordering: EOrdering.Date_publish,
  },
  items: [],
};

export const blogListReducer = (state = defaultState, action: TBlogListActions) => {
  switch (action.type) {
    case EGetBlogListActionType.Loading:
      return { ...state, status: ERequestStatus.Loading };

    case EGetBlogListActionType.Succeed:
      return { ...state, ...action.payload, status: ERequestStatus.Succeed };

    case EGetBlogListActionType.AppendSucceed:
      return {
        ...state,
        meta: action.payload.meta,
        items: [...state.items, ...action.payload.items],
        status: ERequestStatus.Succeed,
      };

    case EGetBlogListActionType.Failed:
      return { ...state, status: ERequestStatus.Failed };

    case EResetBlogListActionType.Reset:
      return defaultState;

    default:
      return state;
  }
};
