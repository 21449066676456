import React, { forwardRef, RefObject, useCallback, useEffect, useRef, useState } from 'react';
import { Textarea as TextareaUiKit, ITextareaProps as ITextareaPropsUiKit } from '@cian/ui-kit';

import { useEventListener } from '../../utils';
import * as s from './Textarea.css';

export interface ITextareaProps extends ITextareaPropsUiKit {
  hasCounter?: boolean;
}

/**
 * Textarea с поддержкой каунта
 */
export const Textarea = forwardRef<HTMLTextAreaElement, ITextareaProps>(
  ({ hasCounter, value, size = 'XS', maxLength, ...otherProps }, ref) => {
    const innerRef = useRef<HTMLTextAreaElement>(null);
    const [count, setCount] = useState(String(value || '').length || 0);

    /** Обновляет каунтер */
    const handleKeyUp = useCallback(
      (e: KeyboardEvent) => {
        setCount((e.target as HTMLTextAreaElement).value.length);
      },
      [setCount],
    );

    /** Слушает нажатия кнопок и обновляет каунтер */
    useEventListener('keyup', handleKeyUp, (ref || innerRef) as RefObject<HTMLTextAreaElement>);

    /** Обновляет каунтер для внешнего value */
    useEffect(() => {
      setCount(String(value || '').length);
    }, [value]);

    return (
      <div className={`${s['wrapper']} ${s['_size_' + size.toLowerCase()]} ${hasCounter ? s['has_counter'] : ''}`}>
        <TextareaUiKit value={value} size={size} maxLength={maxLength} {...otherProps} ref={ref || innerRef} />

        {hasCounter && (
          <div className={s['counter']}>
            {count}
            {maxLength ? `/${maxLength}` : ''}
          </div>
        )}
      </div>
    );
  },
);

Textarea.displayName = 'Textarea';
