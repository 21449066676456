import React from 'react';
import { Image, UIHeading2, useDeviceType } from '@cian/ui-kit';
import { Link } from 'react-router-dom';

import { IArticlesMainAttributesSchema } from '../../repositories/journal/entities/journal/ArticlesMainAttributesSchema';
import { IArticlesMainAttributesRubricSchema } from '../../repositories/journal/entities/journal/ArticlesMainAttributesRubricSchema';
import { UIHeading0 } from '../Typography/UIHeading0';
import { RubricButton } from '../RubricButton';
import { IconWithCount } from '../IconWithText';
import { IconMessage16 } from '../Icons';
import * as s from './MainArticleDetailed.css';

export interface IMainArticleDetailed extends Pick<IArticlesMainAttributesSchema, 'title' | 'image' | 'commentsCount'> {
  rubric?: IArticlesMainAttributesRubricSchema;
  articleUrl: string;
  onRubricButtonClick?(e: React.MouseEvent): void;
  onClick(e: React.MouseEvent): void;
}

/** Топовая статья на Главной (полный вид) */
export const MainArticleDetailed = ({
  image,
  title,
  rubric,
  commentsCount,
  articleUrl,
  onClick,
  onRubricButtonClick,
}: IMainArticleDetailed) => {
  const deviceType = useDeviceType();
  const isPhone = deviceType === 'phone';
  const HeadingComponent = isPhone ? UIHeading2 : UIHeading0;

  return (
    <div className={s['wrapper']} data-web-ui-test-id="MainArticleDetailed">
      <Link to={articleUrl} className={s['title']} onClick={onClick}>
        <HeadingComponent>{title}</HeadingComponent>
      </Link>

      {Boolean(rubric || commentsCount) && (
        <div className={s['activity-panel']}>
          {rubric && (
            <RubricButton url={rubric.url} onClick={e => onRubricButtonClick && onRubricButtonClick(e)}>
              {rubric.name.toLowerCase()}
            </RubricButton>
          )}

          {commentsCount ? <IconWithCount count={commentsCount} icon={<IconMessage16 />} /> : null}
        </div>
      )}

      <Link to={articleUrl} className={s['image-wrapper']} onClick={onClick}>
        <Image
          alt={title}
          objectFit="cover"
          src={image || ''}
          theme="light"
          title={title}
          height={isPhone ? '100%' : 370}
        />
      </Link>
    </div>
  );
};
