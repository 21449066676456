import React from 'react';
import { UIHeading2 } from '@cian/ui-kit';
import { plural } from '@cian/utils';

import { numberWithDelimiter } from '../../utils';
import { SortButton, ISortButton } from './components/SortButton';
import { PostCommentsNotifySwitcher } from '../PostCommentsNotifySwitcher';
import { DetailsSeparator } from '../Details';
import { IPostCommentsNotifySwitcher } from '../PostCommentsNotifySwitcher/PostCommentsNotifySwitcher';
import { EOrdering } from '../../repositories/journal/v1/get-comments';
import * as s from './PostCommentsHeader.css';

export interface IPostCommentsHeader {
  commentsCount: number;
  ordering?: EOrdering;
  isDisabled?: boolean;
  onSort?: ISortButton['onClick'];
  onSwitch?: IPostCommentsNotifySwitcher['onChange'];
}

/**
 * Шапка листинга комментариев
 */
export const PostCommentsHeader = ({ commentsCount, ordering, isDisabled, onSort, onSwitch }: IPostCommentsHeader) => {
  const preparedCommentsCount = numberWithDelimiter(commentsCount);

  return (
    <div className={s['wrapper']}>
      <UIHeading2>
        {preparedCommentsCount} {plural(commentsCount, ['комментарий', 'комментария', 'комментариев'])}
      </UIHeading2>

      {commentsCount ? (
        <SortButton onClick={(...args) => onSort && onSort(...args)} value={ordering} isDisabled={isDisabled} />
      ) : (
        <DetailsSeparator />
      )}

      <PostCommentsNotifySwitcher onChange={(...args) => onSwitch && onSwitch(...args)} isDisabled={isDisabled} />
    </div>
  );
};
