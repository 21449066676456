/**
 * Сокращает тысячные/миллионные разряды числа "1000 -> 1K"
 */
export const cutNumber = (number: number) => {
  if (number > 999999) {
    return String(number).slice(0, -6) + 'm';
  }

  if (number > 999) {
    return String(number).slice(0, -3) + 'k';
  }

  return number;
};
