import React from 'react';

import { Typography } from './components/Typography';
import { Title } from './components/Title';
import { Details } from './components/Details';
import { Description } from './components/Description';
import { MainImage } from './components/MainImage';
import { Microdata } from './components/Microdata';
import { IJournalAttributesSchema } from '../../repositories/journal/entities/journal/JournalAttributesSchema';
import * as s from './Post.css';

export interface IPost
  extends Pick<
    IJournalAttributesSchema,
    'commentsCount' | 'disableComments' | 'image' | 'numViews' | 'subtitle' | 'title' | 'type'
  > {
  content: string;
  dateModified: IJournalAttributesSchema['updatedAt'];
  datePublished: IJournalAttributesSchema['datePublish'];
  postUrl: string;
  authorPostsCount?: number;
  authorId?: number;
  authorName?: string;
  postRegionName?: string;
}

export const Post = ({
  authorPostsCount,
  authorId,
  authorName,
  commentsCount,
  content,
  dateModified,
  datePublished,
  disableComments,
  image,
  numViews,
  postRegionName,
  postUrl,
  subtitle,
  title,
  type,
}: IPost) => {
  return (
    <div className={s['wrapper']}>
      <div className={s['title-wrapper']}>
        <Title>{title}</Title>
      </div>

      <div className={s['details-wrapper']}>
        <Details
          authorPostsCount={authorPostsCount}
          authorId={authorId}
          commentsCount={commentsCount}
          datePublished={datePublished}
          disableComments={disableComments}
          numViews={numViews}
          postRegionName={postRegionName}
          postUrl={postUrl}
          type={type}
        />
      </div>

      <Microdata authorName={authorName} dateModified={dateModified} datePublished={datePublished} />

      {image && (
        <div className={s['main-image-wrapper']}>
          <MainImage src={image} alt={title} />
        </div>
      )}

      {subtitle && (
        <div className={s['description-wrapper']}>
          <Description>{subtitle}</Description>
        </div>
      )}

      <Typography>
        {/* eslint-disable-next-line react/no-danger */}
        <div className={s['content-wrapper']} dangerouslySetInnerHTML={{ __html: content }} />
      </Typography>
    </div>
  );
};
