import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { SidebarDiscussions, SkeletonSidebarDiscussions } from '../../components/SidebarDiscussions';
import { selectDiscussions } from '../../selectors/discussions';
import { ERequestStatus } from '../../types/requestStatus';
import { getDiscussions, resetDiscussions } from '../../actions/discussions';
import { TThunkDispatch } from '../../types/redux';
import { POST_CARD_DISCUSSIONS_REQUEST_LIMIT } from '../../constants';

export const SidebarDiscussionsContainer = () => {
  const dispatch = useDispatch<TThunkDispatch>();
  const { items: discussions, status: discussionsStatus } = useSelector(selectDiscussions);

  /** Запрашивает список дискуссий, если еще ни разу не запрашивал */
  useEffect(() => {
    if (discussionsStatus === ERequestStatus.Initial) {
      dispatch(getDiscussions({ limit: POST_CARD_DISCUSSIONS_REQUEST_LIMIT })).finally();
    }
  }, [dispatch, discussionsStatus]);

  /** При выходе сбрасывает список обсуждений */
  useEffect(() => {
    return () => {
      dispatch(resetDiscussions());
    };
  }, [dispatch]);

  if (discussionsStatus === ERequestStatus.Loading) {
    return <SkeletonSidebarDiscussions />;
  }

  return <SidebarDiscussions items={discussions} />;
};
