import { ITypedReduxAction } from '../../../types/redux/actionType';
import { ICommentList } from '../../../types/commentList';

export enum EGetPostCommentsActionType {
  Loading = 'postComments/getPostComments/loading',
  Succeed = 'postComments/getPostComments/succeed',
  AppendSucceed = 'postComments/getPostComments/appendSucceed',
  Failed = 'postComments/getPostComments/failed',
}

export type TGetPostCommentsLoading = ITypedReduxAction<EGetPostCommentsActionType.Loading>;
export type TGetPostCommentsSucceed = ITypedReduxAction<EGetPostCommentsActionType.Succeed, ICommentList>;
export type TGetPostCommentsAppendSucceed = ITypedReduxAction<EGetPostCommentsActionType.AppendSucceed, ICommentList>;
export type TGetPostCommentsFailed = ITypedReduxAction<EGetPostCommentsActionType.Failed>;

export type TGetPostComments =
  | TGetPostCommentsLoading
  | TGetPostCommentsSucceed
  | TGetPostCommentsAppendSucceed
  | TGetPostCommentsFailed;
