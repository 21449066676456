import React from 'react';
import { useDeviceType } from '@cian/ui-kit';

import { numberWithDelimiter, cutNumber } from '../../utils';
import { IconWithText, IIconWithText } from './IconWithText';

interface IIconWithCount {
  count: number;
  icon: IIconWithText['icon'];
}

/**
 * Иконка и число
 */
export const IconWithCount = ({ count, icon }: IIconWithCount) => {
  const deviceType = useDeviceType();
  const isPhone = deviceType === 'phone';
  const preparedCount = isPhone ? cutNumber(count) : numberWithDelimiter(count);

  return <IconWithText text={String(preparedCount)} icon={icon} />;
};
