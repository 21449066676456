import React from 'react';

import * as s from './IconWithText.css';

export interface IIconWithText {
  text: string;
  icon: React.ReactNode;
}

/**
 * Иконка и текст
 */
export const IconWithText = ({ text, icon }: IIconWithText) => (
  <div className={s['wrapper']}>
    <div className={s['icon']}>{icon}</div>
    <div className={s['text']}>{text}</div>
  </div>
);
