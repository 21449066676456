import React from 'react';
import { Button, Sort16 as IconActionSort16 } from '@cian/ui-kit';

import { EOrdering } from '../../../../repositories/journal/v1/get-comments';
import * as s from './SortButton.css';

export enum ESortButtonValue {
  New = 'new',
  Popular = 'popular',
}

export interface ISortButton {
  value?: EOrdering;
  isDisabled?: boolean;
  onClick(e: React.MouseEvent): void;
}

/**
 * Кнопка сортировки комментов
 */
export const SortButton = ({ value = EOrdering.Published_at, isDisabled, onClick }: ISortButton) => {
  return (
    <div className={s['wrapper']}>
      <Button
        beforeIcon={<IconActionSort16 color={isDisabled ? 'gray20_100' : 'primary_100'} />}
        theme={'stroke_secondary'}
        size={'XS'}
        disabled={isDisabled}
        onClick={onClick}
      >
        Сначала {value === EOrdering.Published_at ? 'новые' : 'популярные'}
      </Button>
    </div>
  );
};
