import React from 'react';

import * as s from './DetailsSeparator.css';

/**
 * Разделитель для children
 */
export const DetailsSeparator = () => {
  return <div className={s['wrapper']} />;
};
