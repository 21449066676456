import { TThunkAction } from '../../../types/redux';
import { getPostCommentsLoading } from './getPostCommentsLoading';
import { getPostCommentsSucceed } from './getPostCommentsSucceed';
import { getPostCommentsAppendSucceed } from './getPostCommentsAppendSucceed';
import { getPostCommentsFailed } from './getPostCommentsFailed';
import {
  fetchGetComments,
  TGetCommentsResponse,
  IGetCommentsRequest,
} from '../../../repositories/journal/v1/get-comments';
import { throwError } from '../../../utils';

export interface IGetPostCommentsParams
  extends Pick<IGetCommentsRequest, 'limit' | 'offset' | 'ordering' | 'sortOrder' | 'withCommentId'> {
  postId: number;
  setType?: 'set' | 'append';
}

export const getPostComments = ({
  postId,
  setType = 'set',
  ...otherParams
}: IGetPostCommentsParams): TThunkAction<Promise<void>> => {
  return async (dispatch, getState, context) => {
    const { httpApi, logger } = context;

    dispatch(getPostCommentsLoading());

    try {
      const { response, statusCode }: TGetCommentsResponse = await fetchGetComments({
        httpApi,
        parameters: {
          journalId: postId,
          ...otherParams,
        },
      });

      if (statusCode !== 200) {
        throwError({ message: 'Не удалось загрузить комменты к посту', statusCode, domain: 'actions.getPostComments' });

        return;
      }

      if (setType === 'append') {
        dispatch(getPostCommentsAppendSucceed({ meta: response.meta, items: response?.data || [] }));
      } else {
        dispatch(getPostCommentsSucceed({ meta: response.meta, items: response?.data || [] }));
      }
    } catch (e) {
      logger.error(e, {
        domain: 'actions.getPostComments',
      });

      dispatch(getPostCommentsFailed());
    }
  };
};
