import React from 'react';
import { useDeviceType } from '@cian/ui-kit';
import { Link } from 'react-router-dom';

import { IconNotepad16 } from '../../../Icons/IconNotepad16';
import { IconWithText } from '../../../IconWithText';
import { cutNumber, numberWithDelimiter } from '../../../../utils';
import { PostTypeUrls } from '../../../../constants';
import { EType } from '../../../../repositories/journal/entities/journal/JournalAttributesSchema';
import { IPost } from '../../Post';
import * as s from './BlogLink.css';

interface IRegionName extends Pick<IPost, 'authorId'> {
  /** Количество постов в блоге */
  children?: IPost['authorPostsCount'];
}

/**
 * Ссылка на блог автора с каунтом
 */
export const BlogLink = ({ authorId, children }: IRegionName) => {
  const deviceType = useDeviceType();

  if (!children || !authorId) {
    return null;
  }

  const isPhone = deviceType === 'phone';
  const preparedCount = isPhone ? cutNumber(children) : numberWithDelimiter(children);

  return (
    <Link
      className={s['wrapper']}
      to={`${PostTypeUrls[EType.Blogs]}?author_id=${authorId}`}
      title={`Блог специалиста ${numberWithDelimiter(children)}`}
    >
      <IconWithText text={`Блог специалиста ${preparedCount}`} icon={<IconNotepad16 />} />
    </Link>
  );
};
