import React from 'react';
import { UIHeading2, ArticleParagraph2 } from '@cian/ui-kit';
import { Link } from 'react-router-dom';

import { SidebarCard } from '../SidebarCard';
import { SidebarNewsItem } from './components/SidebarNewsItem';
import { IArticlesNewsListDataV2Schema } from '../../repositories/journal/entities/journal/ArticlesNewsListDataV2Schema';
import { PostTypeUrls } from '../../constants';
import { EType } from '../../repositories/journal/entities/journal/GetJournalMoreDataSchema';
import * as s from './SidebarNews.css';

interface ISidebarNews {
  items: IArticlesNewsListDataV2Schema[];
}

/**
 * Лента новостей в сайдбаре
 */
export const SidebarNews = ({ items }: ISidebarNews) => (
  <SidebarCard>
    <UIHeading2>Главные новости</UIHeading2>

    <div className={s['items-wrapper']}>
      {items.map(({ id, attributes: { title, slug, type, datePublish, imageThumbnail } }) => (
        <SidebarNewsItem
          key={`news-item-${id}`}
          title={title}
          slug={slug}
          type={type}
          datePublish={datePublish}
          imageThumbnail={imageThumbnail}
          id={id}
        />
      ))}
    </div>

    <Link to={PostTypeUrls[EType.News]} className={s['more-link']}>
      <ArticleParagraph2>Смотреть все</ArticleParagraph2>
    </Link>
  </SidebarCard>
);
