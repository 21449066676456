import { ERequestStatus } from '../../../types/requestStatus';
import { TNewsListState } from '../../../types/newsList';
import {
  EGetPostCardNewsActionType,
  EResetPostCardNewsActionType,
  TPostCardNewsActions,
} from '../../../actions/postCardNews';
import { EOrdering } from '../../../repositories/journal/entities/journal/JournalMetaV2Schema';

export const defaultState: TNewsListState = {
  status: ERequestStatus.Initial,
  meta: {
    b2b: false,
    limit: 0,
    offset: 0,
    regionId: 0,
    rubricId: 0,
    tag: null,
    total: 0,
    ordering: EOrdering.Date_publish,
  },
  items: [],
};

export const newsReducer = (state = defaultState, action: TPostCardNewsActions) => {
  switch (action.type) {
    case EGetPostCardNewsActionType.Loading:
      return { ...state, status: ERequestStatus.Loading };

    case EGetPostCardNewsActionType.Succeed:
      return { ...state, ...action.payload, status: ERequestStatus.Succeed };

    case EGetPostCardNewsActionType.Failed:
      return { ...state, status: ERequestStatus.Failed };

    case EResetPostCardNewsActionType.Reset:
      return defaultState;

    default:
      return state;
  }
};
