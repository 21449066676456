import React from 'react';
import { UIHeading1, useDeviceType } from '@cian/ui-kit';

import { UIHeading0 } from '../../../Typography/UIHeading0';
import { unescapeHtml } from '../../../../../app/helpers/make_html_helper';
import { IJournalAttributesSchema } from '../../../../repositories/journal/entities/journal/JournalAttributesSchema';

export interface IPostTitle {
  children: IJournalAttributesSchema['title'];
}

/**
 * Компонент заголовка поста
 */
export const Title = ({ children }: IPostTitle) => {
  const deviceType = useDeviceType();
  const HeadingComponent = deviceType === 'desktop' ? UIHeading0 : UIHeading1;

  return <HeadingComponent itemProp="headline">{unescapeHtml(children)}</HeadingComponent>;
};
