import React, { ChangeEvent, useCallback, useState, FocusEvent } from 'react';
import { Button, Outside } from '@cian/ui-kit';

import { Textarea } from '../Textarea';
import { PostCommentsNotifySwitcher } from '../PostCommentsNotifySwitcher';
import * as s from './PostCommentForm.css';

export interface IPostCommentForm {
  onSubmit(value: string, isChecked: boolean): void;
  value?: string;
  isChecked?: boolean;
  isAlwaysExpanded?: boolean;
  onChangeValue?(value: string): void;
  onChangeCheckbox?(value: boolean): void;
  onFocus?(e: FocusEvent<HTMLTextAreaElement>): boolean | undefined;
}

/**
 * Форма для отправки комментария
 */
export const PostCommentForm = ({
  onSubmit,
  value,
  isChecked,
  onChangeValue,
  onChangeCheckbox,
  onFocus,
  isAlwaysExpanded,
}: IPostCommentForm) => {
  const [isExpanded, setIsExpanded] = useState(isAlwaysExpanded === true);
  const [innerValue, setInnerValue] = useState('');
  const [checkboxState, setCheckboxState] = useState(isChecked || false);

  const toggleExpansion = useCallback(
    (isActivated: boolean) => {
      if (isAlwaysExpanded) {
        return;
      }

      setIsExpanded(isActivated);
    },
    [isAlwaysExpanded],
  );

  const handleChangeValue = useCallback(
    (e: ChangeEvent<HTMLTextAreaElement>, textareaValue: string) => {
      // только, если управление не из вне
      if (value === undefined) {
        setInnerValue(textareaValue);
      }

      if (onChangeValue) {
        onChangeValue(textareaValue);
      }
    },
    [onChangeValue, value],
  );

  const handleCheck = useCallback(
    (e: ChangeEvent<HTMLInputElement>, value: boolean) => {
      setCheckboxState(value);

      if (onChangeCheckbox) {
        onChangeCheckbox(value);
      }
    },
    [onChangeCheckbox],
  );

  return (
    <Outside active={!isAlwaysExpanded} onOutside={() => toggleExpansion(false)} insideRefs={[]}>
      <div className={s['wrapper']}>
        <Textarea
          hasCounter={isExpanded}
          placeholder="Прокомментировать"
          maxLength={300}
          size={'M'}
          value={value || innerValue}
          onChange={handleChangeValue}
          onFocus={e => {
            if (onFocus) {
              return !onFocus(e) && toggleExpansion(true);
            }

            toggleExpansion(true);
          }}
          minRows={isExpanded ? 3 : undefined}
          maxRows={isExpanded ? undefined : 1}
        />

        <div className={`${s['controls']} ${isExpanded ? s['_shown'] : ''}`}>
          <div className={s['controls-wrapper']}>
            <Button onClick={() => onSubmit(value || innerValue, checkboxState)} disabled={!value && !innerValue}>
              Опубликовать
            </Button>

            <PostCommentsNotifySwitcher isChecked={checkboxState} onChange={handleCheck} />
          </div>
        </div>
      </div>
    </Outside>
  );
};
