import React, { useCallback, useRef } from 'react';
import { Outside } from '@cian/ui-kit';

import { ICommentParentSchema } from '../../../../repositories/journal/entities/comments/CommentParentSchema';
import { IJournalAttributesSchema } from '../../../../repositories/journal/entities/journal/JournalAttributesSchema';
import { PostCommentForm } from '../../../PostCommentForm';
import { UserCard } from '../UserCard';
import { BestAdviceLabel } from '../Labels';
import { getCleanText } from '../../../../../app/helpers/make_html_helper';
import { useCollapse } from '../../../../utils';
import { CommentFormSwitcher } from '../CommentFormSwitcher';
import * as s from './ListItem.css';

export interface IListItem extends Pick<ICommentParentSchema, 'authorObject' | 'comment'> {
  datePublished: ICommentParentSchema['datePublish'];
  isBestAdvice?: ICommentParentSchema['bestAdvice'];
  postAuthorId?: IJournalAttributesSchema['authorId'];
  before?: React.ReactNode;
  children?: React.ReactNode;
  onSendComment(value: string, isChecked: boolean): void;
  onOpenForm?(e: React.MouseEvent): boolean | undefined;
}

/**
 * Элемент листинга комментариев
 */
export const ListItem = ({
  datePublished,
  comment,
  children,
  isBestAdvice,
  authorObject,
  postAuthorId,
  onSendComment,
  onOpenForm,
}: IListItem) => {
  const commentFormRef = useRef<HTMLDivElement>(null);
  const commentFormSwitcherRef = useRef<HTMLDivElement>(null);
  const [toggleCommentForm, isCommentFormShown] = useCollapse(commentFormRef, { transitionDurationMs: 200 });
  const [toggleCommentFormSwitcher, isCommentFormSwitcherShown] = useCollapse(commentFormSwitcherRef, {
    isDefaultOpened: true,
    transitionDurationMs: 100,
    closingTransitionDurationMs: 200,
  });

  const toggleCommentFormVisibility = useCallback(
    (showCommentForm?: boolean) => {
      toggleCommentForm(showCommentForm !== undefined ? showCommentForm : undefined);
      toggleCommentFormSwitcher(showCommentForm !== undefined ? !showCommentForm : undefined);
    },
    [toggleCommentForm, toggleCommentFormSwitcher],
  );

  return (
    <div className={s['wrapper']}>
      <div className={s['headnote']}>
        <UserCard
          datePublished={datePublished}
          authorObject={authorObject}
          isAuthorLabelShown={(postAuthorId && postAuthorId === authorObject?.userId) || false}
        />
        {isBestAdvice && <BestAdviceLabel />}
      </div>

      {/* eslint-disable-next-line react/no-danger */}
      <div className={s['comment']} dangerouslySetInnerHTML={{ __html: getCleanText(comment) }} />

      <div className={`${s['footnote']} ${!isCommentFormSwitcherShown ? s['_empty'] : ''}`}>
        <CommentFormSwitcher
          onClick={e => {
            if (onOpenForm) {
              return !onOpenForm(e) && toggleCommentFormVisibility(true);
            }

            toggleCommentFormVisibility(true);
          }}
          ref={commentFormSwitcherRef}
        />
      </div>

      <Outside active={isCommentFormShown} onOutside={() => toggleCommentFormVisibility(false)} insideRefs={[]}>
        <div ref={commentFormRef}>
          <div className={s['form-wrapper']}>
            <PostCommentForm onSubmit={onSendComment} isAlwaysExpanded isChecked />
          </div>
        </div>
      </Outside>

      {children && <div className={s['comments']}>{children}</div>}
    </div>
  );
};
