import React from 'react';

import { IconDocument24 } from '../../Icons';
import { ListingItemType } from '../../../constants/microdata';
import {
  EType,
  IArticlesMainAttributesSchema,
} from '../../../repositories/journal/entities/journal/ArticlesMainAttributesSchema';
import { Headnote, IHeadnote } from '../components/Headnote';
import { Title } from '../components/Title';
import { Description } from '../components/Description';
import * as s from './DocumentCardListingItem.css';

interface IDocumentCardListingItem extends IHeadnote {
  url: string;
  children?: React.ReactNode;
  title?: IArticlesMainAttributesSchema['title'];
  subtitle?: string;
  onArticleClick?(e: React.MouseEvent): void;
}

/**
 * Элемент списка документов в виде карточки
 */
export const DocumentCardListingItem = ({
  title,
  url,
  subtitle,
  rubric,
  datePublish,
  numComments = 0,
  numLikes = 0,
  children,
  noIndex,
  onRubricButtonClick,
  onArticleClick,
}: IDocumentCardListingItem) => {
  return (
    <article itemScope itemType={ListingItemType[EType.Articles]}>
      <div className={s['content-wrapper']} itemProp="articleBody">
        <Headnote
          beforeAll={
            <div className={s['icon-wrapper']}>
              <IconDocument24 color={'black_100'} />
            </div>
          }
          rubric={rubric}
          noIndex={noIndex}
          onRubricButtonClick={onRubricButtonClick}
        />

        <Title url={url} noIndex={noIndex} onClick={onArticleClick}>
          {title}
        </Title>

        <Description>{subtitle}</Description>

        {children}

        <div className={s['footnote-wrapper']}>
          <Headnote datePublish={datePublish} numLikes={numLikes} numComments={numComments} />
        </div>
      </div>
    </article>
  );
};
