import React, { Fragment } from 'react';

import { Skeleton } from '../Skeleton';

/**
 * Скелетон поста
 */
export const SkeletonPost = () => {
  return (
    <>
      <Skeleton width={'97%'} height={32} margin={'0 0 12px 0'} />
      <Skeleton width={'73%'} height={32} margin={'0 0 32px 0'} />

      <Skeleton height={366} margin={'0 0 32px 0'} borderRadius={8} />

      <Skeleton height={20} margin={'0 0 14px 0'} />
      <Skeleton height={20} margin={'0 0 14px 0'} />
      <Skeleton width={'93.5%'} height={20} margin={'0 0 14px 0'} />
      <Skeleton width={'46.6%'} height={20} margin={'0 0 30px 0'} />

      {[...Array(2)].map((_, index) => (
        <Fragment key={index}>
          <Skeleton height={20} margin={'0 0 14px 0'} />
          <Skeleton height={20} margin={'0 0 14px 0'} />
          <Skeleton height={20} margin={'0 0 14px 0'} />
          <Skeleton height={20} margin={'0 0 14px 0'} />
          <Skeleton height={20} margin={'0 0 14px 0'} />
          <Skeleton height={20} margin={'0 0 14px 0'} />
          <Skeleton width={'93.6%'} height={20} margin={'0 0 14px 0'} />
          <Skeleton width={'71%'} height={20} margin={'0 0 30px 0'} />
        </Fragment>
      ))}
    </>
  );
};
