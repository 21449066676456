import React, { CSSProperties } from 'react';

import { Skeleton, ISkeletonParams } from './Skeleton';
import * as s from './RoundedSkeleton.css';

export interface IRoundSkeletonParams extends ISkeletonParams {
  size: CSSProperties['width'];
}

/**
 * Фейковый элемент для загрузки
 */
export const RoundedSkeleton = ({ size = '100%', ...otherProps }: IRoundSkeletonParams) => {
  return (
    <div className={s['wrapper']}>
      <Skeleton width={size} height={size} {...otherProps} />
    </div>
  );
};
