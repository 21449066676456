import React from 'react';

import * as s from './Details.css';

interface IDetails {
  children: React.ReactNode;
  size?: 'm' | 's';
}

/**
 * Список из иконок с каунтами
 */
export const Details = ({ children, size = 's' }: IDetails) => {
  return <div className={`${s['wrapper']} ${s[`_size_${size}`]}`}>{children}</div>;
};
