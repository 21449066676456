import React from 'react';
import { Switch } from '@cian/ui-kit';

import * as s from './PostCommentsNotifySwitcher.css';

export interface IPostCommentsNotifySwitcher {
  isChecked?: boolean;
  isDisabled?: boolean;
  onChange?(event: React.ChangeEvent<HTMLInputElement>, value: boolean): void;
}

/**
 * Переключатель для отправки оповещений о новых комментах
 */
export const PostCommentsNotifySwitcher = ({ isChecked, isDisabled, onChange }: IPostCommentsNotifySwitcher) => {
  return (
    <div className={s['wrapper']}>
      <Switch label={'Оповещать о новых комментариях'} checked={isChecked} disabled={isDisabled} onChange={onChange} />
    </div>
  );
};
