import { ICurrentRegion } from '../../../types/geo';
import { IRegionsItem } from '../../../types/regions';

interface IGetPostRegionName {
  globalRegionId: ICurrentRegion['id'];
  postRegionIds: number[];
  dictionaryRegions: IRegionsItem[];
  defaultRegionName?: string;
}

/**
 * Подбирает подходящий регион для поста
 * - Ищет глобальный регион у поста и возвращает его имя
 * - Возвращает первый в списке регион у поста
 * - Возвращает дефолтный регион
 */
export const findPostRegionName = ({
  globalRegionId,
  postRegionIds,
  dictionaryRegions,
  defaultRegionName = 'Вся Россия',
}: IGetPostRegionName) => {
  /** Если у поста нет регионов, то возвращает дефолтный */
  if (!postRegionIds.length || !dictionaryRegions.length) {
    return defaultRegionName;
  }

  /** Если в массиве регионов поста есть глобальный регион, то возвращает его */
  if (postRegionIds.includes(globalRegionId)) {
    return (
      dictionaryRegions.find(dictionaryRegion => dictionaryRegion.id === globalRegionId)?.displayName ||
      defaultRegionName
    );
  }

  /** Возвращает первый регион из массива регионов поста */
  return (
    dictionaryRegions.find(dictionaryRegion => dictionaryRegion.id === postRegionIds[0])?.displayName ||
    defaultRegionName
  );
};
