import { ERequestStatus } from '../../../types/requestStatus';
import { TCommentListState } from '../../../types/commentList';
import { POST_CARD_COMMENTS_REQUEST_LIMIT } from '../../../constants';
import {
  TPostCommentsActions,
  EGetPostCommentsActionType,
  EResetPostCommentsActionType,
} from '../../../actions/postComments';

export const defaultState: TCommentListState = {
  status: ERequestStatus.Initial,
  meta: {
    limit: POST_CARD_COMMENTS_REQUEST_LIMIT,
    offset: 0,
    total: 0,
  },
  items: [],
};

export const commentsReducer = (state = defaultState, action: TPostCommentsActions) => {
  switch (action.type) {
    case EGetPostCommentsActionType.Loading:
      return { ...state, status: ERequestStatus.Loading };

    case EGetPostCommentsActionType.Succeed:
      return { ...state, ...action.payload, status: ERequestStatus.Succeed };

    case EGetPostCommentsActionType.AppendSucceed:
      return {
        ...state,
        meta: action.payload.meta,
        items: [...state.items, ...action.payload.items],
        status: ERequestStatus.Succeed,
      };

    case EGetPostCommentsActionType.Failed:
      return { ...state, status: ERequestStatus.Failed };

    case EResetPostCommentsActionType.Reset:
      return defaultState;

    default:
      return state;
  }
};
